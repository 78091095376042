import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import styles from "./MDXWrapper.module.css";
import components from "./components";

const MDXWrapper = ({ children }) => (
  <section className={styles.__section}>
    <MDXProvider components={components}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  </section>
);

export default MDXWrapper;
