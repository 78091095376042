import React from "react";
import { Link } from "gatsby";
import { Text } from "@bloobirds-it/bloobirds-platform-component-library";

import styles from "./Navbar.module.css";
import bloobirdsLogo from "../../images/bloobirds-logo.svg";

const NavLink = ({ children, active, to }) => (
  <li className={styles.__link}>
    <Link to={to}>
      <Text inline color={active ? "bloobirds" : "peanut"} size="m">
        {children}
      </Text>
    </Link>
  </li>
);

const BloobirdsLogo = () => (
  <a rel="noopener noreferrer" target="_blank" href="https://bloobirds.com">
    <div className={styles.__branding_container}>
      <img className={styles.__logo} src={bloobirdsLogo} alt="bloobirds logo" />
      <div className={styles.__branding_text}>
        <Text size="m" color="peanut" weight="bold" align="center">
          bloobirds
        </Text>
        <Text size="m" uppercase color="bloobirds" weight="bold" align="center">
          api
        </Text>
      </div>
    </div>
  </a>
);

const Navbar = () => (
  <header className={styles.__navbar}>
    <BloobirdsLogo />
    <div className={styles.__actions}>
      <nav>
        <ul className={styles.__actions_list}>
          <NavLink to="/" active>
            Docs
          </NavLink>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://support.bloobirds.com"
          >
            <Text inline color="peanut" size="m">
              Knowledge Base
            </Text>
          </a>
        </ul>
      </nav>
    </div>
  </header>
);
export default Navbar;
