import React from "react";
import { Text } from "@bloobirds-it/bloobirds-platform-component-library";

import Card from "../Card/Card";
import Response from "../Response/Response";
import Arguments from "../Arguments/Arguments";
import CodeBlock from "../CodeBlock/CodeBlock";
import Request from "../Request/Request";
import { Example, ExampleCodeBlock } from "../Example/Example";

import styles from "./MDXWrapper.module.css";
import introductionImg from "../../images/introduction.svg";

const HorizontalList = ({ children }) => {
  return <ul className={styles.__horizontal_list}>{children}</ul>;
};

const HorizontalListItem = ({ title, children }) => {
  return (
    <li className={styles.__horizontal_list_item}>
      <Text size="s" color="peanut" weight="bold" inline>
        {title}
      </Text>
      <Text size="s" color="peanut" inline>
        {children}
      </Text>
    </li>
  );
};

const IntroductionText = ({ children }) => {
  return (
    <div className={styles.__paragraph}>
      <Text size="m" color="peanut">
        {children}
      </Text>
    </div>
  );
};

const components = {
  Arguments,
  Response,
  CodeBlock,
  Request,
  HorizontalList,
  HorizontalListItem,
  Example,
  ExampleCodeBlock,
  IntroductionText,
  img: () => (
    <img className={styles.__img} src={introductionImg} alt="introductionImg" />
  ),
  h1: ({ children }) => (
    <div className={styles.__h1}>
      <Text color="bloobirds" size="xxxl" weight="bold">
        {children}
      </Text>
    </div>
  ),
  ul: ({ children }) => {
    return <ul className={styles.__list}>{children}</ul>;
  },
  li: ({ children }) => (
    <li className={styles.__list_item}>
      <Text size="s" color="peanut" inline>
        {children}
      </Text>
    </li>
  ),
  h2: ({ children }) => (
    <div className={styles.__h2}>
      <Text color="bloobirds" size="xxl" weight="bold">
        {children}
      </Text>
    </div>
  ),
  p: ({ children }) => (
    <div className={styles.__paragraph}>
      <Text size="s" color="peanut">
        {children}
      </Text>
    </div>
  ),
  wrapper: ({ children }) => {
    const sections = children.reduce((acc, curr) => {
      if (curr.props.mdxType === "h1" || curr.props.mdxType === "h2") {
        acc.push({ content: [curr], endpoints: [] });
      } else if (acc[acc.length - 1]) {
        if (
          curr.props.mdxType === "Request" ||
          curr.props.mdxType === "Response" ||
          curr.props.mdxType === "img" ||
          curr.props.mdxType === "ExampleCodeBlock"
        ) {
          acc[acc.length - 1].endpoints.push(curr);
        } else {
          acc[acc.length - 1].content.push(curr);
        }
      }

      return acc;
    }, []);

    return sections.map((section, id) => (
      <Card
        key={id}
        style={{ animationDelay: `${id * 150}ms` }}
        content={section.content}
        endpoints={section.endpoints}
      />
    ));
  }
};

export default components;
