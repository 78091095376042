import React from "react";
import { Text } from "@bloobirds-it/bloobirds-platform-component-library";

import styles from "./Example.module.css";
import CodeBlock from "../CodeBlock/CodeBlock";
import themes from "../themes";
import CopyToClipboardButton from "../CopyToClipboardButton/CopyToClipboardButton";

export const ExampleCodeBlock = ({ language, method, url, token, body }) => {
  const headers = [
    `--request ${method}`,
    `--url '${url}'`,
    "--header 'accept: application/json'",
    "--header 'content-type: application/json'",
    `--header 'authorization: Bearer ${token}'`,
    "--data: "
  ];

  const request = `curl ${headers.join(" \\  \n")}${JSON.stringify(
    body,
    null,
    2
  )}`;
  return (
    <div>
      <div className={styles.__code_container}>
        <p>EXAMPLE</p>
        <CopyToClipboardButton text={request} />
      </div>
      <CodeBlock language={language} theme={themes.example}>
        {request}
      </CodeBlock>
    </div>
  );
};

export const Example = ({ description }) => {
  const { text, code } = { ...description };
  return (
    <div className={styles.__container}>
      <Text size="l" color="peanut" weight="bold">
        Example
      </Text>
      <div className={styles.__hr} />
      <Text size="s" color="peanut" weight="regular">
        {text}
      </Text>
      <Text size="s" color="peanut" weight="regular">
        <code>{code}</code>
      </Text>
    </div>
  );
};
