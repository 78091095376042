import React from "react";
import { Text } from "@bloobirds-it/bloobirds-platform-component-library";

import styles from "./Arguments.module.css";

const ArgumentItem = props => {
  const { name, description, optional, value } = props;

  return (
    <li
      className={`${styles.__argument} ${description &&
        styles.__argument__description}`}
    >
      <div className={styles.__argument_props}>
        <Text size="m" weight="medium" color="peanut">
          {name}
        </Text>
        <Text
          size="s"
          weight="regular"
          color={optional ? "softPeanut" : "tangerine"}
        >
          {`${optional ? "optional" : "required"}, `}
        </Text>
        {value !== undefined && (
          <Text size="s" weight="regular" color="softPeanut">
            {optional && "defaults to "}
            {value}
          </Text>
        )}
      </div>
      {description && (
        <div className={styles.__argument_description}>
          <Text size="s" weight="regular" color="peanut">
            {description}
          </Text>
        </div>
      )}
    </li>
  );
};

const Arguments = ({ items }) => (
  <div className={styles.__container}>
    <Text color="peanut" weight="bold">
      Arguments
    </Text>
    <div className={styles.__hr} />
    <ul className={styles.__list}>
      {items.map(item => (
        <ArgumentItem {...item} />
      ))}
    </ul>
  </div>
);

export default Arguments;
