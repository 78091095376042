import React from "react";

import styles from "./Response.module.css";
import themes from "../themes";
import CodeBlock from "../CodeBlock/CodeBlock";

const Response = ({ title, body }) => (
  <div>
    <header className={styles.__header}>
      <p>{title}</p>
    </header>
    <CodeBlock language="json" theme={themes.response}>
      {JSON.stringify(body, null, 2)}
    </CodeBlock>
  </div>
);

Response.defaultProps = {
  title: "RESPONSE"
};

export default Response;
