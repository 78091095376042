import React from "react";
import { graphql } from "gatsby";

import Sidebar from "../Sidebar/Sidebar";
import MDXWrapper from "../MDXWrapper/MDXWrapper";
import Navbar from "../Navbar/Navbar";
import SEO from "../SEO/SEO";

const Layout = ({ data }) => {
  const { mdx } = data;

  return (
    <main>
      <SEO title={mdx.frontmatter.title} />
      <Navbar />
      <div style={{ display: "flex" }}>
        <Sidebar current={mdx.id} />
        <MDXWrapper>{mdx.body}</MDXWrapper>
      </div>
    </main>
  );
};

export default Layout;

export const pageQuery = graphql`
  query DocPage($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
      }
    }
  }
`;
