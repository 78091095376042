import React, { useState } from "react";
import {
  IconButton,
  Tooltip
} from "@bloobirds-it/bloobirds-platform-component-library";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyToClipboardButton = ({ text }) => {
  const [copied, setCopied] = useState(false);
  return (
    <div>
      <Tooltip title={copied ? "Copied!" : "Copy"} position="top">
        <CopyToClipboard text={text}>
          <IconButton
            name="copy"
            size="16"
            color="white"
            onClick={() => setCopied(true)}
          />
        </CopyToClipboard>
      </Tooltip>
    </div>
  );
};
export default CopyToClipboardButton;
