import React from "react";
import { Text } from "@bloobirds-it/bloobirds-platform-component-library";

import styles from "./Request.module.css";
import themes from "../themes";
import CodeBlock from "../CodeBlock/CodeBlock";
import CopyToClipboardButton from "../CopyToClipboardButton/CopyToClipboardButton";

const Request = ({ method, path, body, basePath }) => {
  const BASE_PATH = "https://api.bloobirds.com/{objectType}";

  return (
    <div className={styles.__container}>
      <header className={styles.__header}>
        <div className={styles.__header_text}>
          <p>{method}</p>
          {!basePath && (
            <Text size="xs" color="softBanana" weight="medium">
              {path}
            </Text>
          )}
        </div>
        <CopyToClipboardButton text={basePath ? path : BASE_PATH + path} />
      </header>
      {basePath ? (
        <div className={styles.__basepath_text}>
          <Text size="xs" color="softBanana" weight="medium">
            {path}
          </Text>
        </div>
      ) : (
        body && (
          <CodeBlock language="json" theme={themes.request}>
            {JSON.stringify(body, null, 2)}
          </CodeBlock>
        )
      )}
    </div>
  );
};

export default Request;
