import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";

export default ({ children, language, theme }) => (
  <Highlight
    {...defaultProps}
    theme={theme}
    code={children}
    language={language}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre
        className={className}
        style={{
          ...style,
          padding: "12px",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          fontFamily: "Menlo",
          fontSize: "12px",
          lineHeight: "16px"
        }}
      >
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
);
