import bashTheme from "./bashTheme";
import requestTheme from "./requestTheme";
import responseTheme from "./responseTheme";

const themes = {
  example: bashTheme,
  request: requestTheme,
  response: responseTheme
};

export default themes;
