const theme = {
  plain: {
    color: "#D4E0F1",
    backgroundColor: "#596068"
  },
  styles: [
    {
      types: ["comment", "prolog", "doctype", "cdata"],
      style: {
        color: "#999988",
        fontStyle: "italic"
      }
    },
    {
      types: ["namespace"],
      style: {
        opacity: 0.7
      }
    },
    {
      types: ["string", "attr-value", "number"],
      style: {
        color: "#a7ffdb"
      }
    },
    {
      types: ["punctuation", "operator", "function"],
      style: {
        color: "#c5d1dd"
      }
    },
    {
      types: [
        "entity",
        "url",
        "symbol",
        "constant",
        "property",
        "regex",
        "inserted"
      ],
      style: {
        color: "#9acfff"
      }
    },
    {
      types: ["boolean"],
      style: {
        color: "#ffb3c2"
      }
    },
    {
      types: ["atrule", "keyword", "attr-name", "selector"],
      style: {
        color: "#00a4db"
      }
    },
    {
      types: ["deleted", "tag"],
      style: {
        color: "#d73a49"
      }
    },
    {
      types: ["function-variable"],
      style: {
        color: "#6f42c1"
      }
    },
    {
      types: ["tag", "selector", "keyword"],
      style: {
        color: "#00009f"
      }
    }
  ]
};

export default theme;
