import React from "react";

import styles from "./Card.module.css";

const Card = ({ content, endpoints, style }) => {
  return (
    <div className={styles.__card} style={style}>
      <div className={styles.__content_card_wrapper}>
        <div className={styles.__content_wrapper}>{content}</div>
        {endpoints.length ? (
          <div className={styles.__endpoints_wrapper}>{endpoints}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Card;
