import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title }) => {
  const data = useStaticQuery(graphql`
    query Query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const defaultTitle = data.site.siteMetadata.title;

  return (
    <>
      <Helmet>
        <title>{`${title} - ${defaultTitle}`}</title>
      </Helmet>
    </>
  );
};

export default SEO;
