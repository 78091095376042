import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Icon, Text } from "@bloobirds-it/bloobirds-platform-component-library";

import styles from "./Sidebar.module.css";

const SidebarLink = ({ key, slug, title, active }) => (
  <li className={styles.__list_item} key={key}>
    <Link to={slug}>
      <div className={styles.__container}>
        <span className={styles.__arrow}>
          <Icon name={active ? "arrowRight" : ""} size={16} color="bloobirds" />
        </span>
        <Text
          color={active ? "bloobirds" : "softPeanut"}
          size="m"
          weight="medium"
        >
          {title}
        </Text>
      </div>
    </Link>
  </li>
);

const Sidebar = ({ current }) => {
  const data = useStaticQuery(graphql`
    query DocumentQuery {
      allMdx(sort: { order: ASC, fields: fileAbsolutePath }) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const allMdxs = data.allMdx.edges;
  return (
    <aside className={styles.__sidebar}>
      <ul>
        {allMdxs.map(({ node }) => {
          const { slug } = node.fields;
          const { title } = node.frontmatter;

          return (
            <SidebarLink
              key={node.id}
              slug={slug}
              title={title}
              active={node.id === current}
            />
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
